import {useLocalStorage} from '@rehooks/local-storage';
import {COINS_TO_CURRENCY_EXCHANGE_RATE, LocalStorageKeys, UIPriceCurrency} from 'common/types';
import {useSelector} from 'react-redux';
import {ExtendedUserInfo} from 'screens/common/CurrentUser';
import {AppState} from 'store';

export const useCurrentUser = (): null | ExtendedUserInfo => {
  const user = useSelector((state: AppState) => state.user.info.data);
  const [uiPriceCurrency] = useLocalStorage(LocalStorageKeys.UIPriceCurrency);

  if (user === null) {
    return null;
  }

  // tslint:disable-next-line:no-object-literal-type-assertion
  return {
    ...user,
    uiPriceCurrency: (Object.values(UIPriceCurrency) as any).includes(
      uiPriceCurrency
    )
      ? uiPriceCurrency
      : UIPriceCurrency.Coins
  } as ExtendedUserInfo;
};

// Simplified currency calculator
export const coinsInCurrency = (coins: number, currency: UIPriceCurrency) => {
  const exchangeRate = COINS_TO_CURRENCY_EXCHANGE_RATE[currency];
  const value = coins * exchangeRate;

  return new Intl.NumberFormat('uk-UA', {style: 'currency', currency: 'UAH'}).format(value);
};
