import React, {FunctionComponent} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {AppState} from 'store';
import * as UserSelectors from 'store/user/selectors';
import {createOffersManagementUrl} from 'utils/navigation';
import {createDashboardUrl} from 'utils/navigation';
import {RoleId} from '../../../common/types';

const mapStateToProps = (state: AppState) => ({
  user: UserSelectors.userInfo(state),
});

interface GuestRouteProps
  extends RouteProps,
    ReturnType<typeof mapStateToProps> {}

const GuestRoute: FunctionComponent<GuestRouteProps> = ({
  component: Component,
  user,
  location,
  ...props
}: GuestRouteProps) => {
  return (
    <Route
      {...props}
      render={componentProps => {
        const omitCheckUser = location && location.pathname == '/registration' && location.search.indexOf('referral') > -1;
        if (user && !omitCheckUser) {
          const dashboardUrl = createDashboardUrl({});
          const offersManagementUrl = createOffersManagementUrl({});

          return <Redirect to={user.roleId == RoleId.Realtor ? dashboardUrl : offersManagementUrl}/>;
        }
        // @ts-ignore
        return <Component {...componentProps} />;
      }}
    />
  );
};

const ConnectedGuestRoute = connect(mapStateToProps)(GuestRoute);

export default ConnectedGuestRoute;
